import React from "react"
import { graphql} from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Top, TopImg, Title, InfoBlock, Info, InfoTitle, Dot, Content, ImageBlock, Para, ContentImg} from "../styles/marineStyles"
import BackImg from "../images/back.png"
import BargingImg from "../images/barging.png"
import ContentBarging from "../images/barging2.png"
import ContactForm from "../components/contact"

export default class Barging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    }
  }
  
render() {
  return (
      <Layout>
        <Seo 
          title="Barging Services"
          description="Transporting your dock can be a hassle, but it doesn't have to be. Jakes on the Lakes can make it easy. We have the experience and knowledge needed to get your property where it needs to be."
          keywords={["Jake On The Lakes", "Barging Services", "Coeur d’Alene Lake", "Hauser Lake", "Newman Lake", "Priest Lake", "Pend Oreille Lake", "Cocolalla Lake", "Spokane River", "Expertise In The Northwest", process.env.DRUPAL_WEBFORM_ENDPOINT, process.env.GATSBY_PHONE_NUMBER]}
       />
        <Top>
          <Title>BARGING SERVICES</Title>
          <div><Dot/><Dot/><Dot/></div>
          <TopImg style={{backgroundImage: `url(${BargingImg})`}}></TopImg>
        </Top>
        <InfoBlock style={{backgroundImage: `url(${BackImg})`}}>
          <Info>
            <InfoTitle>EXPERTISE IN THE NORTHWEST</InfoTitle>
            <div><Dot/><Dot/><Dot/></div>
            <p>Transporting your dock can be a hassle, but it doesn't have to be. Jakes on the Lakes can make it easy. We have the experience and knowledge needed to get your property where it needs to be.</p>
          </Info>
        </InfoBlock>
        <Content>
          <Para>Jakes on the Lakes is more than just a transportation service. With more than a decade of dock building and barging experience you can have confidence that your property will be transported safely. We take pride in our flexibility and always prioritize our customer’s needs.<br/><br/> Whether you’re on Coeur d’Alene Lake, Hauser Lake, Newman Lake, Priest Lake, Pend Oreille Lake, Cocolalla Lake, Liberty Lake Or Spokane River, Jakes on the Lakes is there for all your barging needs. Contact us today!</Para>
          <ImageBlock>
            <ContentImg src={ContentBarging} alt="Barging in Action"/>
          </ImageBlock>
        </Content>
        <ContactForm data={this.props.data} />
      </Layout>
    )
  }
}

export const query = graphql`
query {
  webformWebform(drupal_internal__id: { eq: "9gk5ucvtcrek9dgqtqq28a8fkvvhkdb2" }) {
    drupal_internal__id
    elements {
        name
        type
        attributes {
          name
          value
        }
    }
  }
}
`