import styled from "styled-components";
import { Link } from "gatsby"

const Top = styled.div`
    color: #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Raleway', sans-serif;
`;

const Title = styled.h1`
    font-size: 45px;
    text-align: center;
    margin-bottom: 40px; 
    margin-top: 9%;
    letter-spacing: 16.5px;
    line-height: initial;
    padding-left: 3%;
    paddingright: 3%;
    font-weight: normal;
    @media (max-width: 768px) {
        margin-top: 100px;
        font-size: 40px;
        letter-spacing: 8px;
    }
    @media (max-width: 450px) {
        margin-top: 100px;
        font-size: 35px;
        letter-spacing: 5px;
    }
`;

const TopImg = styled.div`
    width: 80%;
    margin: 20px;
    margin-top: 40px; 
    background-size: cover;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;

`;

const InfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #707070;
    background-size: cover;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    padding-bottom: 16%;
    padding-top: 5%;
    @media (max-width: 1024px) {
        padding-top: 10%;
    }
    @media (max-width: 700px) {
        padding-top: 5%;
    }
    @media (max-width: 575px) {
        padding-top: 0%;
    }
    
`;

const Info = styled.div`
    width: 80%;
    margin: 30px;
    div {
        margin: 20px;
    }
`;

const InfoTitle = styled.div`
    font-size: 28px;
    
`;

const BtnGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
`;

const Button = styled(Link)`
    font-size: 28px;
    border: solid #707070;
    border-width: thin;
    padding: 10px;
    width: 33%;
    color: #707070;
    text-decoration: none;
    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 30px;
        margin-top: -10px;
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Dot = styled.div`
height: 8px;
width: 8px;
background-color: #fff;
border-radius: 50%;
display: inline-block;
border: solid #707070;
border-width: 1px;
margin-left: 18px;
margin-right: 18px;
`;

const Content = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    color: #707070;
    background-size: cover;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    width: 80%;
    justify-content: center;
    margin-left: 10%;
    margin-top: -16%;
    margin-bottom: 5%;
    @media (max-width: 768px) {
        
    }
`;

const Para = styled.div`
    font-size: 18px;
    text-align: left;
    width: 60%;
    padding-right: 10%;
    @media (max-width: 768px) {
        width: 100%;
        order: 2;
        margin-bottom: 5%;
        text-align: center;
        padding: 0;
    }
`;

const ImageBlock = styled.div`
    width: 40%;
    @media (max-width: 768px) {
        width: 100%;
        order: 1;
        margin-bottom: 15%;
    }
`;

const ContentImg = styled.img`
    margin: 0px;
`;

export {
    Top,
    Title,
    TopImg,
    InfoBlock,
    Info,
    InfoTitle,
    BtnGroup,
    Button,
    List,
    Dot,
    Content,
    ImageBlock,
    Para,
    ContentImg
}